@import url(https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&family=Roboto:wght@300;400;500;700;900&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
  src: url('/fonts/MrsEavesXLSerifOT-Reg.eot');
  font-family: 'MrsEavesXLSerifOT-Reg';
  src:
    url('/fonts/MrsEavesXLSerifOT-Reg.woff2') format('woff2'),
    url('/fonts/MrsEavesXLSerifOT-Reg.woff') format('woff'),
    url('/fonts/MrsEavesXLSerifOT-Reg.eot?#iefix') format('embedded-opentype'),
    url('/fonts/MrsEavesXLSerifOT-Reg.ttf') format('truetype');
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font-size: 16px;
  line-height: 1;
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
  text-decoration: none;
  color: #333;
}

